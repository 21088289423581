import React from 'react';
import { Link, graphql } from 'gatsby';
import ReactDisqusComments from 'react-disqus-comments';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Bio from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { rhythm, scale } from '../utils/typography';
import ReadingTime from '../components/reading-time';
import TagList from '../components/tag-list/tag-list';

class BlogPostTemplate extends React.Component {
  render() {
    const { coverImage, mdx: post } = this.props.data;
    const siteTitle = this.props.data.site.siteMetadata.title;
    const { previous, next } = this.props.pageContext;
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          coverImage={coverImage && coverImage.publicURL}
          keywords={post.frontmatter.tags.map(tag => tag.toLowerCase())}
        />
        <h1 style={{ marginTop: `2.75rem` }}>{post.frontmatter.title}</h1>
        <p
          style={{
            ...scale(-1 / 5),
            display: `block`,
            marginBottom: rhythm(1),
            marginTop: rhythm(-1)
          }}
        >
          {post.frontmatter.date}
          <strong>{` ${String.fromCharCode(8729)} `}</strong>
          <ReadingTime tag="span" minutes={post.fields.readingTime.minutes} />
        </p>

        <MDXRenderer>{post.body}</MDXRenderer>

        {post.frontmatter.tags && (
          <div style={{ marginBottom: `1.25rem` }}>
            <TagList tags={post.frontmatter.tags} />
          </div>
        )}

        <hr
          style={{
            marginBottom: rhythm(1)
          }}
        />
        <Bio />

        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
        <ReactDisqusComments
          shortname="smellycode"
          identifier={post.id}
          title={post.title}
          url={`${this.props.data.site.siteMetadata.siteUrl}${this.props.location.pathname}`}
        />
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      fields {
        readingTime {
          minutes
        }
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
      }
    }
    coverImage: file(name: { regex: $slug }) {
      publicURL
    }
  }
`;
